import Player from "@vimeo/player";
import Mediabox from 'mediabox';
import Rellax from 'rellax';


// Progress bar

const progressIndicator = document.querySelector('.header-progress-indicator');

function updateProgressIndicator() {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bodyHeight = document.body.scrollHeight;
    const scrollPercentage = scrollPosition / bodyHeight;

    progressIndicator.style.height = scrollPercentage * 100 + '%';
}

window.addEventListener('scroll', updateProgressIndicator);
updateProgressIndicator();


// Parallax items

new Rellax('.rellax', {
    center: true,
    breakpoints: [512, 768, 960]
});


// Outro video lightbox

Mediabox('.mediabox');


// Videos

const videoContainers = document.querySelectorAll('.section-video');

videoContainers.forEach(container => {
    let bufferingIsDone = false;

    const player = new Player(container.id, {
        url: container.dataset.videoId,
        width: container.dataset.videoWidth,
        autopause: false,
        controls: false,
        muted: true,
        loop: true,
        dnt: true
    });

    player.on('bufferend', () => {
        container.classList.add('visible');
        bufferingIsDone = true;
    });

    const observer = new IntersectionObserver(entries => {
        const entry = entries[0];

        if (entry.isIntersecting) {
            player.play();
        } else {
            if (bufferingIsDone) {
                player.setCurrentTime(0);
                player.pause();
            }
        }
    }, {threshold: [.1, .2, .3, .4, .5, .6, .7, .8, .9, 1]});

    observer.observe(container);
});